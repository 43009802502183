@import "~less-variables";

body:has([data-scoreboard-window="true"]) {
    overflow-y: hidden;
}

.text-upcase() {
    text-transform: uppercase !important;
    letter-spacing: 0.03em !important;
}

// -------------------------------------
//   Scoreboard Fit
// -------------------------------------

// Lanes 9, 10
.scoreboardFit {
    --font-size-factor: 0vw;
    --title-font-size: 4.5vw;
    --time-font-size: 6.5vw;
    --dot-size: 4.5vw;
    --table-font-size: 3.2vw;
    --table-cell-lane-width: 5.5%;
    --table-cell-team-width: 27%;
    --table-cell-place-width: 5.5%;
    --table-cell-result-width: 16%;

    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
    overflow-y: hidden;
    background-color: @scoreboard-bg;
    color: #fff;

    .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1vw 1vw 1.5vw;
        min-width: 0;

        > div {
            min-width: 0;
        }
    }

    .headerTitle {
        margin: 0;
        font-size: calc(var(--title-font-size) + var(--font-size-factor));
        font-weight: @font-weight-bold;
        line-height: 1.1;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .headerMeta {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            display: flex;
            align-items: center;
            font-size: calc(var(--table-font-size) + var(--font-size-factor));

            &:after {
                width: 1vw;
                height: 1vw;
                content: "";
                margin-left: 1vw;
                margin-right: 1vw;
                border-radius: 100%;
                background-color: @color-gray-50;
            }

            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
    }

    .headerTime {
        font-size: calc(var(--time-font-size) + var(--font-size-factor));
        padding-left: 2.7vw;
        line-height: 1;
        font-weight: @font-weight-semi;
    }

    .headerDot {
        background-color: @color-green-40;
        width: calc(var(--dot-size) + var(--font-size-factor));
        height: calc(var(--dot-size) + var(--font-size-factor));
        border-radius: 50%;
        padding-left: 2.7vw;
    }

    .body {
        overflow-y: auto;
        margin: 0 1vw;
    }

    .table {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0 0.5vw;

        > tbody {
            > tr {
                background-color: @scoreboard-row-bg;

                > th,
                > td {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 0 1.35vw;
                    vertical-align: middle;
                    font-size: calc(
                        var(--table-font-size) + var(--font-size-factor)
                    );
                    line-height: 1.25;
                    border: 0;
                }

                .cellTeam {
                    font-size: 2.8vw;
                }
            }
        }
    }

    .cellLane {
        width: var(--table-cell-lane-width);
        padding-left: 0 !important;
        font-weight: @font-weight-bold;
        text-align: end;
    }

    .cellPrimary {
        min-width: 41%;
        font-weight: @font-weight-bold;
        .text-upcase();
    }

    .cellTeam {
        width: var(--table-cell-team-width);
        padding-right: 2.7vw !important;
        .text-upcase();
    }

    .cellPlace {
        width: var(--table-cell-place-width);
        padding: 0 !important;
        font-weight: @font-weight-bold;
        text-align: center;
    }

    .cellPlaceFinished {
        background-color: @color-light-blue-80;
    }

    .cellResult {
        width: var(--table-cell-result-width);
        text-align: end;
        font-weight: @font-weight-bold;
    }

    .cellScore {
        width: 11.5%;
        padding: 0 0.4vw !important;
        border-right: 0.125vw solid @scoreboard-row-bg !important;
        border-left: 0.125vw solid @scoreboard-row-bg !important;
        background-color: lighten(@scoreboard-row-bg, 14%);
        font-weight: @font-weight-bold;
        text-align: center;
    }

    .cellScoreWon {
        background-color: @brand-success;
    }

    .cellInvisible {
        background-color: @scoreboard-row-bg;
        color: @scoreboard-row-bg;
    }

    // Lane Options
    &.lanes-4,
    &.lanes-5,
    &.lanes-6 {
        --table-cell-lane-width: 4.5%;

        &.cellTeamHidden {
            --font-size-factor: 1.9vw;
            --table-cell-lane-width: 5.5%;
            --table-cell-place-width: 7.5%;
            --table-cell-result-width: 24.5%;
        }
    }

    &.lanes-7,
    &.lanes-8 {
        --table-cell-lane-width: 4.5%;

        &.cellTeamHidden {
            --font-size-factor: 0.9vw;
            --table-cell-lane-width: 5.5%;
            --table-cell-place-width: 6.5%;
            --table-cell-result-width: 21%;
        }
    }
}

// -------------------------------------
//   Scoreboard
// -------------------------------------

.scoreboard {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow-y: hidden;
    background-color: @scoreboard-bg;
    color: #fff;

    .header {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        padding: 12px 15px;
        min-width: 0;

        > div {
            min-width: 0;
        }
    }

    .headerTitle {
        margin: 0 0 2px;
        font-size: 16px;
        font-weight: @font-weight-semi;
        line-height: 1.3;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .headerMeta {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;
        list-style: none;

        > li {
            display: flex;
            align-items: center;
            font-size: 14px;

            &:after {
                width: 3px;
                height: 3px;
                content: "";
                margin-left: 6px;
                margin-right: 6px;
                border-radius: 100%;
                background-color: @color-gray-50;
            }

            &:last-child {
                &:after {
                    content: none;
                }
            }
        }
    }

    .headerTime {
        font-size: 18px;
        line-height: 1;
        font-weight: @font-weight-semi;
    }

    .headerDot {
        min-width: 30px !important;
        min-height: 30px !important;
        border-radius: 50%;
        background-color: @color-green-40;
    }

    .body {
        overflow-y: auto;
        margin: 0;
        height: fit-content; // Safari fix
    }

    .table {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0 0 2px;

        > tbody {
            > tr {
                background-color: @scoreboard-row-bg;

                > th,
                > td {
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding: 7px 15px;
                    vertical-align: middle;
                    font-size: 16px;
                    border: 0;
                }
            }
        }
    }

    .cellLane {
        width: 25px;
        padding-right: 0 !important;
        padding-left: 0 !important;
        font-weight: @font-weight-semi;
        text-align: end;
    }

    .cellPrimary {
        min-width: 41%;
        font-weight: @font-weight-semi;
    }

    .cellTeam {
        font-size: 13px;
        line-height: 1.25;
        font-weight: @font-weight-semi;
        color: @color-gray-50;
    }

    .cellPlace {
        width: 50px;
        padding: 0 !important;
        font-weight: @font-weight-semi;
        text-align: center;
    }

    .cellPlaceFinished {
        background-color: @color-light-blue-80;
    }

    .cellResult {
        width: 95px;
        text-align: end;
        font-weight: @font-weight-semi;
    }

    .cellScore {
        width: 65px;
        padding: 0 !important;
        border-right: 1px solid @scoreboard-row-bg !important;
        border-left: 1px solid @scoreboard-row-bg !important;
        background-color: lighten(@scoreboard-row-bg, 14%);
        font-weight: @font-weight-semi;
        text-align: center;
    }

    .cellScoreWon {
        background-color: @brand-success;
    }

    .cellInvisible {
        background-color: @scoreboard-row-bg;
        color: @scoreboard-row-bg;
    }
}
